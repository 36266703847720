import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2604bdd5"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = { class: "image-cropper-actions" }
const _hoisted_3 = {
  key: 0,
  class: "btn-group"
}
const _hoisted_4 = ["onClick"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createElementVNode("img", {
        ref: "image_ref",
        src: $setup.src,
        alt: $props.file.name
      }, null, 8 /* PROPS */, _hoisted_1)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        ($props.fixed_aspect_ratio == null)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.aspect_ratio_buttons, (button) => {
                return (_openBlock(), _createElementBlock("button", {
                  type: "button",
                  class: _normalizeClass(["btn btn-default btn-sm", {
							active: isNaN($setup.aspect_ratio)
								? isNaN(button.value)
								: button.value === $setup.aspect_ratio,
						}]),
                  key: button.label,
                  onClick: $event => ($setup.aspect_ratio = button.value)
                }, _toDisplayString(button.label), 11 /* TEXT, CLASS, PROPS */, _hoisted_4))
              }), 128 /* KEYED_FRAGMENT */))
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _createElementVNode("div", null, [
        ($props.fixed_aspect_ratio == null)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "btn btn-sm margin-right",
              onClick: _cache[0] || (_cache[0] = $event => ($setup.emit('toggle_image_cropper')))
            }, _toDisplayString(_ctx.__("Back")), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        _createElementVNode("button", {
          class: "btn btn-primary btn-sm",
          onClick: $setup.crop_image
        }, _toDisplayString(_ctx.__("Crop")), 1 /* TEXT */)
      ])
    ])
  ]))
}